import { React, useState } from "react";
import { useSelector } from "react-redux";
import { useFormContext, useWatch, useController } from "react-hook-form";
import {
  getFlightsByType,
  getFiltersOptions,
  filterFlightsData,
} from "../selectors/flights";
import {
  getFirstDestination,
  getDepartureCityLabel,
  getDestination,
  getLastSelectedIsland,
} from "../selectors/destination";
import ValidateButton from "../components/ValidateButton";
import FlightRow from "../components/FlightRow";
import Select from "../components/Select";
import chevronLeft from "../img/icons/arrow-right-main-color.png";
import outbound from "../img/icons/outbound.svg";
import inbound from "../img/icons/inbound.svg";
import CancelButton from "../components/CancelButton";
import warning from "../img/icons/warning.svg";

const FlightStepTwo = ({ block, ToStepOne, index }) => {
  const { field } = useController({
    name: `flight.external.code`,
  });
  const [value, setValue] = useState(field.value || "");
  const { getValues } = useFormContext();
  const form_values = getValues();
  const { label_destination, code } = useSelector(getFirstDestination);
  const last_selected_island = useSelector(getLastSelectedIsland);
  let sec_destination_label = "";
  let { next_dest } = form_values;
  sec_destination_label = useSelector(getDestination(next_dest))[
    "label_destination"
  ];
  const return_destination =
    sec_destination_label === undefined
      ? label_destination
      : sec_destination_label;

  const radioInputValue = useWatch({
    name: `flight.external.code`,
  });

  const { cabin_aller, cabin_retour, departureCity } =
    form_values["flight"]["external"];

  const departure_city_label = useSelector(
    getDepartureCityLabel(departureCity)
  );

  const flight_datas = useSelector(getFlightsByType("external"));
  const isButtonDisabled =
    radioInputValue !== undefined && radioInputValue in flight_datas
      ? false
      : true;

  const {
    company_options,
    stopover_options,
    duration_options,
    bagage_options,
    price_options,
  } = getFiltersOptions(flight_datas);

  const company_filter = useWatch({
    name: `flight.external.company`,
  });

  const stop_filter = useWatch({
    name: `flight.external.stop`,
  });

  const duration_filter = useWatch({
    name: `flight.external.duration`,
  });

  const cabin_filter = useWatch({
    name: `flight.external.cabin`,
  });

  const bagage_filter = useWatch({
    name: `flight.external.bagage`,
  });

  const price_filter = useWatch({
    name: `flight.external.price`,
  });

  const filteredData = filterFlightsData(
    flight_datas,
    company_filter,
    stop_filter,
    duration_filter,
    cabin_filter,
    price_filter,
    bagage_filter
  );

  return (
    <div className="modal">
      <div className="modal-content max-w-1172">
        <div className="flex justify-between pb-5">
          <h3 className="gold-color text-fs-21 leading-lh-37 merriweather font-bold ">
            Vols
          </h3>
          <button
            onClick={() => ToStepOne()}
            className="main-color text-fs-14 border-b-main-color flex self-center border-b-1-5 border-accent"
          >
            Modifier
          </button>
        </div>
        {code === "POL" &&
          last_selected_island !== "PPT" &&
          last_selected_island !== "" && (
            <div className="flex text-fs-14 px-5 py-3.5 bg-gold rounded-md items-center mb-value-30 bigdesktop:w-1132 bigdesktop:-ml-235">
              <img className="self-start mr-5" src={warning} alt="" />
              <span className="text-white block font-semibold">
                Votre dernière île de séjour n'étant pas Tahiti, nous vous
                recommandons de sélectionner un vol retour en soirée.
              </span>
            </div>
          )}
        <div className="bg-grey-pagination rounded-md flex justify-between main-color mb-value-30 tablet:flex-col">
          <div className="desktop:w-1/2 desktop:border-r desktop:border-r-gray-200 tablet:border-b tablet:border-b-gray-200 flex py-14 pl-5">
            <div className="flex items-center">
              <img src={outbound} alt="outbound" />
              <span className="text-fs-14 ml-2.5 font-bold">Départ</span>
              <div className="bg-white rounded-md flex items-center h-value-30 px-5 ml-value-25">
                <span className="text-fs-14">{departure_city_label}</span>
                <img
                  className="ml-5 w-1.5"
                  src={chevronLeft}
                  alt="chevron-left"
                />
                <span className="text-fs-14 ml-5">{label_destination} </span>
              </div>
              <div className="bg-white rounded-md flex items-center h-value-30 px-5 ml-4">
                <span className="text-fs-14">
                  {cabin_aller === "Y"
                    ? "Economique"
                    : cabin_aller === "C"
                    ? "Affaire"
                    : "Première"}
                </span>
              </div>
            </div>
          </div>
          <div className="desktop:w-1/2 flex py-14 pl-5">
            <div className="flex items-center">
              <img src={inbound} alt="inbound" />
              <span className="text-fs-14 ml-2.5 font-bold">Retour</span>
              <div className="bg-white rounded-md flex items-center h-value-30 px-5 ml-value-25">
                <span className="text-fs-14">{return_destination}</span>
                <img
                  className="ml-5 w-1.5"
                  src={chevronLeft}
                  alt="chevron-left"
                />
                <span className="text-fs-14 ml-5">{departure_city_label}</span>
              </div>
              <div className="bg-white rounded-md flex items-center h-value-30 px-5 ml-4">
                <span className="text-fs-14">
                  {cabin_retour === "Y"
                    ? "Economique"
                    : cabin_retour === "C"
                    ? "Affaire"
                    : "Première"}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-between tablet:flex-wrap">
          <div className="flex [&>select]:mr-2.5 tablet:[&>select]:mb-2.5  [&>select:last-child]:ml-0 tablet:flex-wrap">
            <Select
              id={`flight.external.company`}
              filter_name={`company`}
              default_value={"all"}
              options={company_options}
              ClassNames={
                "custom-select-arrow-2 border-1-5 border-accent font-semibold"
              }
            />

            <Select
              id={`flight.external.stop`}
              filter_name={`stop`}
              default_value={"all"}
              options={stopover_options}
              ClassNames={
                "custom-select-arrow-2 border-1-5 border-accent font-semibold"
              }
            />

            <Select
              id={`flight.external.duration`}
              filter_name={`duration`}
              default_value={"all"}
              options={duration_options}
              ClassNames={
                "custom-select-arrow-2 border-1-5 border-accent font-semibold"
              }
            />

            <Select
              id={`flight.external.bagage`}
              filter_name={`bagage`}
              default_value={"all"}
              options={bagage_options}
              ClassNames={
                "custom-select-arrow-2 border-1-5 border-accent font-semibold"
              }
            />
          </div>

          <div className="flex items-center tablet:w-full tablet:justify-center tablet:mt-5">
            <span className="mr-5 text-fs-14 main-color">Trier par</span>

            <Select
              id={`flight.external.price`}
              filter_name={`price`}
              default_value={"all"}
              options={price_options}
              ClassNames={
                "custom-select-arrow-2 border-1-5 border-accent font-semibold"
              }
            />
          </div>
        </div>

        {filteredData.length === 0 && (
          <p className="main-color text-fs-16 flex justify-center mx-auto my-5">
            Aucun vol ne correspond à vos filtres.
          </p>
        )}

        {filteredData.length > 0 &&
          filteredData.map((item) => {
            return (
              <FlightRow
                key={item}
                id={item}
                keyObject={item}
                name={`flight.external.code`}
                flight_datas={flight_datas[item]}
                hidden={false}
                field={field}
                setValue={setValue}
                value={value}
                block={block}
                button={{
                  type: "SELECT_FLIGHT",
                }}
              />
            );
          })}

        <div className="flex flex-col mt-value-30">
          <ValidateButton
            classes={
              "p-16-35 leading-lh-15 block mx-auto main-bg-color text-white br-26 text-fs-14 w-value-226 disabled:bg-disabled-button"
            }
            button={{
              label: "Valider",
              type: "SELECT_FLIGHT",
            }}
            disabled={isButtonDisabled}
            block={block}
          />
          <CancelButton block={block} index={index} />
        </div>
      </div>
    </div>
  );
};

export default FlightStepTwo;
